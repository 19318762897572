import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";

import * as LocationActions from './location.actions';
import { STATE_KEYS } from "../../core/utilities/constants";
import { City, Country, LocationState, State } from "../../interfaces";

export const initialLocationState: LocationState = {
    countries: [],
    states: [],
    cities: []
}

const _locationReducer = createReducer(
        initialLocationState,
        on(LocationActions.setAllCountries, (state, { countries }) => ({ ...state, countries: countries })),
        on(LocationActions.setAllStates, (state, { states }) => ({...state, states: states })),
        on(LocationActions.setAllCities, (state, { cities }) => ({ ...state, cities: cities })
    )
)

// meta reducer
export function locationReducer(state: any, action: any) {
    return _locationReducer(state, action);
}

export const selectLocationState = createFeatureSelector<LocationState>(STATE_KEYS.LOCATION);

export const selectAllCountriesSelector = createSelector(selectLocationState, state => [...state.countries || []]);
export const selectAllStatesSelector = createSelector(selectLocationState, state => [...state.states || []]);
export const selectAllCitiesSelector = createSelector(selectLocationState, state => [...state.cities || []]);


export const getCountriesSelector = (all: boolean = false) => createSelector(
    selectAllCountriesSelector, 
    (countries: Country[]) => {
        if (!countries?.length) return [];

        if (!!all) {
            // return all countries
            return [...countries || []]
        }
        // return all active countries
        return [...countries?.filter((country) => country.IsActive == 1) || []];
    }
);

/**
 * get country by SNo
 * @param countrySNo accepts country.SNo value
 * @returns country list with equivalent SNo
 */
export const getCountryByCountrySNoSelector = (countrySNo: number) => createSelector(
    selectAllCountriesSelector, 
    (countries: Country[]) => {
        if (!countries?.length) return [];

        return countries?.filter((country) => country.SNo == countrySNo)?.map((country) => ({...country, isChecked: false }))
    }
);

// Get grouped states list of selected countries
export const getStateGroupedListByCountriesSelector = (countries: Country[]) => createSelector(
    selectAllStatesSelector,
    (states: State[]) => {
        if (!countries?.length) return [];

        return [...countries?.map((country: Country) => {
            const countryItem: Country = {...country, States: [], isChecked: false};
            countryItem.States = [...states?.filter(state => country.SNo == state.Country_SNo)]?.map((item => ({...item, isChecked: false})))
            return {...countryItem};
        }) || []]
    }
);

// Get grouped cities list of selected states
export const getCityGroupedListByStatesSelector = (states: State[]) => createSelector(
    selectAllCitiesSelector,
    (cities: City[]) => {
        if (!states?.length) return [];

        return [...states?.map((state: State) => {
            const stateItem: State = {...state, Cities: [], isChecked: false};
            stateItem.Cities = [...cities?.filter(city => state.SNo == city.State_SNo)]?.map((city) => ({...city, isChecked: false}))
            return {...stateItem};
        }) || []]
    }
);

/**
 * 
 * @param countrySNo country SNo
 * @param all , to fetch active and inactive records.
 * @returns State[]
 */
export const getStatesByCountrySNoSelector = (countrySNo: number, all: boolean = false) => createSelector(
    selectAllStatesSelector, 
    (states: State[]) => {
        if (!states.length) return [];

        if (!!all) {
            return [...states?.filter((state) => state.Country_SNo == countrySNo)]; // all states
        }
        return [...states?.filter((state) => state.Country_SNo == countrySNo && state.IsActive == 1)]; // active states only
    }
);

/**
 * get state by SNo
 * @param SNo accepts state SNo value
 * @returns states list with equivalent SNo
 */
export const locationGetStatesByStateSNoSelector = (stateSNo: number, all: boolean = false) => createSelector(
    selectAllStatesSelector, 
    (states: State[]) => {
        if (!states?.length) return [];

        if (!!all) {
            return [...states?.filter((state) => state.SNo == stateSNo)]; // all states
        }
        return [...states?.filter((state) => state.SNo == stateSNo && state.IsActive == 1)]; // active states
    }
);

export const getCitiesByStateSNoSelector = (stateSNo: number, all: boolean = false) => createSelector(
    selectAllCitiesSelector, 
    (cities: City[]) => {
        if (!cities?.length) return [];

        if (!!all) {
            return [...cities?.filter((city) => city.State_SNo == stateSNo)]; // all cities
        }
        return [...cities?.filter((city) => city.State_SNo == stateSNo && city.IsActive == 1)]; // active cities only
    }
);

/**
 * get cities by SNo
 * @param citySNo accepts city.SNo value
 * @returns cities list with equivalent SNo
 */
export const locationGetCitiesByCitySNoSelector = (citySNo: number, all: boolean = false) => createSelector(
    selectAllCitiesSelector, 
    (cities: City[]) => {
        if (!cities?.length) return [];

        if (!!all) {
            return [...cities?.filter((city) => city.SNo == citySNo)]; // all cities with same sno
        }
        return [...cities?.filter((city) => city.SNo == citySNo && city.IsActive == 1)]; // active cities with same sno and active.
    }
);



