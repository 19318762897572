import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { CoreModule } from './core/core.module';
import { LOCAL_STORAGE_KEY } from './core/utilities/constants';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { AuthState, UserCredentialModel } from './interfaces';
import { Store } from '@ngrx/store';

import * as AuthActions from './state/auth/auth.actions';
import { StorageClientService } from './core/services/storage-client.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, CoreModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'sm-admin-web';
  private _subscription: Subscription[] = [];


  constructor(
    private authStore: Store<AuthState>,
    private storage: StorageClientService,
    private primeNgConfig: PrimeNGConfig,
  ) {
    this.primeNgConfig.ripple = true;
  }

  ngOnInit(): void {
      
    // const emailId = this.storage.get(LOCAL_STORAGE_KEY.EMAIL);
    // const password =  this.storage.get(LOCAL_STORAGE_KEY.PASSWORD);
    
    // combineLatest([emailId, password]).subscribe(
    //   ([email_id, password_local]) => {
    //     if (!!email_id && !!password_local) {
    //       const cred: UserCredentialModel = { EmailAddress: email_id, Password: password_local, RememberMe: true };
    //       this.authStore.dispatch(AuthActions.loginRequest({ loginModel: cred }));
    //     }
    //   }
    // )
  }

  ngOnDestroy(): void {
      if (!!this._subscription?.length) {
        this._subscription.forEach((subscription) => subscription.unsubscribe())
      }
  }
  
}
