import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, tap } from "rxjs/operators";

import * as AuthActions from '../../state/auth/auth.actions';
import { CommonOperations } from "../../helpers/common-operations";
import { LoginOperationsService } from "../../services/login-operations.service";

@Injectable()
export class AuthEffects {

    loginRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loginRequest),
            switchMap(({ loginModel }) => {
                return this.loginService.login(loginModel).pipe(
                    switchMap(response => {
                        const reqModel = {
                            loginSuccessResponse: response,
                            email: loginModel.EmailAddress,
                            password: loginModel.Password
                        };
                        this.loginService.storeToken(response, loginModel.EmailAddress, loginModel.Password);
                        return of(AuthActions.loginSuccess(reqModel))
                    }),
                    catchError((error, caught) => {
                        return of(AuthActions.loginFailure({ error: JSON.stringify(error) }));
                    })
                )
            }),
        )
    });

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            switchMap(({ loginSuccessResponse, email, password }) => {
                return this.loginService.checkAccess().pipe(
                    map(role => {
                        this.loginService.storeUserRole(role, email, password)
                        return AuthActions.checkAccessSuccess({ role: role })
                    })
                )
            })
        )
    );

    // loginFailure$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(AuthActions.loginFailure),
    //         map(({ error }) => {
    //             this.cOps.showHttpError(error)
    //         })
    //     ), { dispatch: false }
    // );

    checkAccessSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.checkAccessSuccess),
            switchMap(({ role }) => {
                return this.loginService.fetchLoggedUserProfile().pipe(
                    mergeMap(user => {
                        this.loginService.storeUser(user);
                        // this.utilitiesService.dismissSpinner();
                        return [
                            AuthActions.saveLoggedUserState({ user }), 
                            AuthActions.fetchAccountInfo({ userSNo: user.SNo })]
                    })
                )
            })
        )
    );

    saveLoggedUserState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.saveLoggedUserState),
            exhaustMap((user) => this.router.navigate(['/admin/dashboard']))
        ), { dispatch: false }
    );

    fetchLatestProfileDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.fetchLatestProfileDetails),
            switchMap(() => {
                return this.loginService.fetchLoggedUserProfile().pipe(
                    switchMap(user => {
                        this.loginService.storeUser(user);
                        return [AuthActions.updateLoggedUserState({ user }), AuthActions.fetchAccountInfo({ userSNo: user.SNo })]
                    })
                )
            })
        )
    );
    logOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logOut),
            tap(action => {
                this.loginService.logout()
                this.router.navigate(['/auth/login'], { replaceUrl: true })
            }),
        ), { dispatch: false }
    );

    constructor(
        private router: Router,
        private actions$: Actions,
        private cOps: CommonOperations,
        private loginService: LoginOperationsService,
    ) { }
}