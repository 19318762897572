import { Routes } from '@angular/router';
import { ForgotPasswordComponent, LoginComponent } from './modules/auth/components';
import { LocationResolver } from './state/location/location.resolver';
import { authorizeResolver } from './core/guards/authorize.resolver';
import { authorizeLoginResolver } from './core/guards/authorize-login.resolver';

export const routes: Routes = [
    { path: '', redirectTo: '/admin', pathMatch: 'full' },
    { 
        path: 'admin', 
        resolve: { authorize: authorizeResolver, locationsData: LocationResolver },
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)       
    },
    { 
        path: 'account', 
        resolve: { locationsData: LocationResolver },
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) 
    },
    { 
        path: 'auth', 
        loadComponent: () => import('./modules/auth/auth.component').then(m => m.AuthComponent),
        resolve: { authorizeLogin: authorizeLoginResolver },
        children: [
            // { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
            { path: 'login', component: LoginComponent },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            // { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
        ] 
    }
];
