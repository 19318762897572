import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer
  } from '@ngrx/store';
  import {routerReducer} from '@ngrx/router-store';
import { locationReducer } from '../location/location.reducer';
import { STATE_KEYS } from '../../core/utilities/constants';
import { environment } from '../../../environments/environment';
import { authReducer } from '../auth/auth.reducer';
    
  export const reducers: ActionReducerMap<any> = {
        router: routerReducer,
        [STATE_KEYS.LOCATION]: locationReducer,
        [STATE_KEYS.AUTH]: authReducer,
  };
  
  export function logger(reducer:ActionReducer<any>)
      : ActionReducer<any> {
      return (state, action) => {
          return reducer(state, action);
      }
  }
  
  export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];
  
  
  