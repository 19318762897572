import { ResolveFn } from '@angular/router';
import * as AuthSelector from '../../state/auth/auth.reducer';
import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs';
import { AuthState } from '../../interfaces';
import { StorageClientService } from '../services/storage-client.service';
import { LOCAL_STORAGE_KEY } from '../utilities/constants';

export const authorizeLoginResolver: ResolveFn<boolean | Promise<boolean>> = (
  route, 
  state,
  authStore: Store<AuthState> = inject(Store<AuthState>),
  storageClientService: StorageClientService = inject(StorageClientService),
) => {
    return authStore.pipe(
    select(AuthSelector.isLoggedIn),
    map(async (isLoggedIn) => {
      let accessToken = await storageClientService.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
      return !isLoggedIn || !accessToken;
    })
  )
};
