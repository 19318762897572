import { Injectable } from '@angular/core';
import {
  RouterStateSnapshot, Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { LocationState } from '../../interfaces';
import * as LocationActions from '../../state/location/location.actions';
import * as LocationSelectors from '../../state/location/location.reducer';

@Injectable({
  providedIn: 'root'
})
export class LocationResolver implements Resolve<boolean> {
  
  constructor(
    private locationStore: Store<LocationState>
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.locationStore.pipe(
      select(LocationSelectors.selectLocationState),
      tap((locationState) => {
        if (!locationState.countries?.length) {
          this.locationStore.dispatch(LocationActions.getAllCountries());
        }
        if (!locationState.states?.length) {
          this.locationStore.dispatch(LocationActions.getAllStates());
        }
        if (!locationState.cities?.length) {
          this.locationStore.dispatch(LocationActions.getAllCities());
        }
      }),
      filter((locationState) => !!locationState?.countries && !!locationState?.states && !!locationState?.cities),
      take(1),
      switchMap(() => of(false))
    )
  }
}
