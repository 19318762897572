import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { catchError, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';

import { AuthState } from '../../interfaces';
import { LOCAL_STORAGE_KEY } from '../utilities/constants';
import { StorageClientService } from '../services/storage-client.service';
import { LoginOperationsService } from '../../services/login-operations.service';

import * as AuthSelector from '../../state/auth/auth.reducer';
import * as AuthActions from '../../state/auth/auth.actions';

export const authorizeResolver: ResolveFn<boolean> = (
  route, 
  state,
  authStore: Store<AuthState> = inject(Store<AuthState>),
  storageClientService: StorageClientService = inject(StorageClientService),
  loginOperationsService: LoginOperationsService = inject(LoginOperationsService)
) => {
  
  return authStore.pipe(
    select(AuthSelector.isLoggedIn),
    map((isLoggedIn: boolean) => isLoggedIn),
    mergeMap((isLoggedIn) => {
      if (isLoggedIn) return of(isLoggedIn);

      const accessTokenStorage = storageClientService.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
      const refreshTokenStorage = storageClientService.get(LOCAL_STORAGE_KEY.REFRESH_TOKEN);

      return forkJoin([accessTokenStorage, refreshTokenStorage]).pipe(
        mergeMap((results: any[]) => {

          if (!results[0]) {
            authStore.dispatch(AuthActions.logOut());
            return of(false);
          }

          authStore.dispatch(AuthActions.updateAccessToken({ accessToken: results[0], refreshToken: results[1] }));

          return loginOperationsService.fetchLoggedUserProfile().pipe(
            map((userResponse) => {
              if (userResponse) {
                authStore.dispatch(AuthActions.updateLoggedUserState({ user: userResponse }));
                return true;
              }

              authStore.dispatch(AuthActions.logOut());
              return false;
            }),
            catchError((error) => {
              authStore.dispatch(AuthActions.logOut());
              return of(false)
            })
          )
        })
      )
    })
  )
};
