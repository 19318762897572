import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { City, Country, State } from "../../interfaces";
import { LocationService } from "../../helpers/location-service";

import * as LocationActions from './location.actions';

@Injectable()
export class LocationEffects {

    getAllCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.getAllCountries),
            exhaustMap((action) =>
                this.locationService.getAllCountries().pipe(
                    map((response: any) => {
                        return response?.data?.map((country: Country) => {
                            return {...country, isChecked: false}
                        })
                    }),
                    map((response: any) => LocationActions.setAllCountries({ countries: response })),
                    catchError((error, caught) => of())
                )
            )
        )
    });

    getAllStates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.getAllStates),
            exhaustMap((action) =>
                this.locationService.getAllStates().pipe(
                    map((response: any) => {
                        return response?.data?.map((state: State) => {
                            return {...state, isChecked: false}
                        })
                    }),
                    map((response: any) => LocationActions.setAllStates({ states: response })),
                    catchError((error, caught) => of())
                )
            )
        )
    });

    getAllCities$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LocationActions.getAllCities),
            exhaustMap((action) =>
                this.locationService.getAllCities().pipe(
                    map((response: any) => {
                        return response?.data?.map((city: City) => {
                            return {...city, isChecked: false}
                        })
                    }),
                    map((response: any) => LocationActions.setAllCities({ cities: response })),
                    catchError((error, caught) => of())
                )
            )
        )
    });

    constructor(
        private actions$: Actions,
        private locationService: LocationService
    ) { }
}
