import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './state/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { LocationEffects } from './state/location/location.effects';
import { CoreModule } from './core/core.module';
import { AuthEffects } from './state/auth/auth.effects';
import { provideHttpClient, withFetch } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideRouter(
      routes, 
      withPreloading(PreloadAllModules),
      withViewTransitions(), 
      withInMemoryScrolling({ scrollPositionRestoration: 'top' })
    ), 
    provideClientHydration(),
    importProvidersFrom([
      BrowserAnimationsModule,
      CoreModule,
      NgxWebstorageModule.forRoot(),
      StoreModule.forRoot(reducers, {
          metaReducers,
          runtimeChecks: {
              strictStateImmutability: true,
              strictActionImmutability: true,
              strictActionSerializability: true,
              strictStateSerializability: true
          }
      }),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      EffectsModule.forRoot([LocationEffects, AuthEffects])
    ]),
  ]
};
