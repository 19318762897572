import { createAction, props } from "@ngrx/store";
import { City, Country, State } from "../../interfaces";

export const getAllCountries = createAction(
    '[LOCATION] Get All Countries'
)

export const getAllStates = createAction(
    '[LOCATION] Get All States'
)

export const getAllCities = createAction(
    '[LOCATION] Get All Cities'
)

export const setAllCountries = createAction(
    '[LOCATION] Set All Countries',
    props<{ countries: Country[] }>()
)

export const setAllStates = createAction(
    '[LOCATION] Set All States',
    props<{ states: State[] }>()
)

export const setAllCities = createAction(
    '[LOCATION] Set All Cities',
    props<{ cities: City[] }>()
)
